import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const validationSchema = Yup.object({
  name_en: Yup.string().required("Name En required"),
  name_ar: Yup.string().required("Name Ar required"),
  min_sla: Yup.number().min(0).required("Min SLA required"),
  max_sla: Yup.number()
    .min(0)
    .required("Max SLA required")
    .when("$max_sla", (max_sla, schema) => {
      return schema.min(
        Yup.ref("min_sla"),
        "Value must be grater than or equal to Min SLA"
      );
    }),
  threshold: Yup.number()
    .min(0)
    .required("Max SLA required")
    .when("cod_verification", {
      is: true,
      then: Yup.number().min(1, "Threashold must be grater than 0."),
    }),
  max_threshold: Yup.number()
    .min(0)
    .required("Max Threshold required")
    .when("$max_threshold", (max_threshold, schema) => {
      return schema.min(
        Yup.ref("threshold"),
        "Value must be grater than or equal to Min Threshold"
      );
    }),
});

export default function ManageCity(props) {
  const isGulf = localStorage.getItem("region") === "GULF";

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const formik = useFormik({
    initialValues: {
      id: props.data.id,
      name_en: props.data.name_en,
      name_ar: props.data.name_ar,
      enabled: props.data.enabled,
      min_sla: props.data.min_sla,
      max_sla: props.data.max_sla,
      cod_verification: props.data.cod_verification,
      threshold: props.data.threshold,
      max_threshold: props.data.max_threshold,
      fast_delivery: props.data.fast_delivery,
      cutoff_time: props.data.cutoff_time,
      is_payment_auto_refunded: props.data.is_payment_auto_refunded,
      type: "city", //To match with backend DTO
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.actionHandler(values);
    },
  });

  const threshold = () => (
    <>
      <Grid item md={3}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          id="threshold"
          name="threshold"
          label="Min Threshold"
          type="number"
          value={formik.values.threshold}
          onChange={formik.handleChange}
          error={formik.touched.threshold && Boolean(formik.errors.threshold)}
          helperText={formik.touched.threshold && formik.errors.threshold}
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          id="max_threshold"
          name="max_threshold"
          label="Max Threshold"
          type="number"
          value={formik.values.max_threshold}
          onChange={formik.handleChange}
          error={
            formik.touched.max_threshold && Boolean(formik.errors.max_threshold)
          }
          helperText={
            formik.touched.max_threshold && formik.errors.max_threshold
          }
        />
      </Grid>
    </>
  );

  const options = () => (
    <>
      <Grid item md={3}>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.cod_verification}
              name="cod_verification"
              onChange={formik.handleChange}
              color="primary"
            />
          }
          labelPlacement="start"
          label="COD Verificaion"
        />
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.fast_delivery}
              name="fast_delivery"
              onChange={formik.handleChange}
              color="primary"
            />
          }
          labelPlacement="start"
          label="Fast Delivery"
          disabled={!isGulf}
        />
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.cutoff_time}
              name="cutoff_time"
              onChange={formik.handleChange}
              color="primary"
            />
          }
          labelPlacement="start"
          label="Exclude Cut Off Time"
        />
      </Grid>
      <Grid item md={3}>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values.is_payment_auto_refunded}
              name="is_payment_auto_refunded"
              onChange={formik.handleChange}
              color="primary"
            />
          }
          labelPlacement="start"
          label="Auto-Refund Payment"
        />
      </Grid>
    </>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h1>{props.headerMessage}</h1>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <TextField
              id="id"
              name="id"
              type="hidden"
              value={formik.values.id}
              onChange={formik.handleChange}
            />
            <TextField
              fullWidth
              id="name_en"
              name="name_en"
              label="name_en"
              variant="outlined"
              margin="normal"
              value={formik.values.name_en}
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue(`name_en`, value);
                if (!isGulf) formik.setFieldValue(`name_ar`, value);
              }}
              error={formik.touched.name_en && Boolean(formik.errors.name_en)}
              helperText={formik.touched.name_en && formik.errors.name_en}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              id="name_ar"
              name="name_ar"
              label="Name Ar"
              value={formik.values.name_ar}
              onChange={formik.handleChange}
              error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
              helperText={formik.touched.name_ar && formik.errors.name_ar}
            />

            <Grid item md={12}>
              <Grid container spacing={3} display="flex" alignItems="center">
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="min_sla"
                    name="min_sla"
                    label="Min SLA"
                    type="number"
                    value={formik.values.min_sla}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.min_sla && Boolean(formik.errors.min_sla)
                    }
                    helperText={formik.touched.min_sla && formik.errors.min_sla}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="max_sla"
                    name="max_sla"
                    label="Max SLA"
                    type="number"
                    value={formik.values.max_sla}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.max_sla && Boolean(formik.errors.max_sla)
                    }
                    helperText={formik.touched.max_sla && formik.errors.max_sla}
                  />
                </Grid>
                {threshold()}
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.enabled}
                        name="enabled"
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="Enabled"
                  />
                </Grid>
                {options()}
                <Grid item md={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item md={3}>
                  <Button variant="contained" fullWidth onClick={props.close}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid
                container
                spacing={3}
                display="flex"
                alignItems="center"
              ></Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
}
