import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadProvince } from "../../../store/province/provinceActions";
import ActionMenu from "../../actionmenu/Actionmenu";
import BreadcrumbIndex from "../../breadcrumb/BreadcrumbIndex";
import ProvinceListTable from "./ProvinceList";

export default function CountryIndex() {
  const provinceList = useSelector((state) => state.province.provinceList);
  const dispatch = useDispatch();
  const history = useHistory();
  const country = localStorage.getItem("country");
  const breadcrumb = [
    { title: "Home", url: "/mapper/country" },
    { title: country, url: "/mapper/province" },
  ];
  localStorage.setItem("breadcrumb", JSON.stringify(breadcrumb));

  useEffect(() => {
    dispatch(loadProvince(country));
  }, [dispatch, country]);

  const onRowSelected = (province) => {
    localStorage.setItem("provinceId", province.id);
    localStorage.setItem("province", province.name);
    history.push("/mapper/city");
  };

  return (
    <div>
      <BreadcrumbIndex />
      <ActionMenu />
      <ProvinceListTable
        tabaledata={provinceList}
        rowSelected={onRowSelected}
      />
    </div>
  );
}
