import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadArea } from "../../../store/area/areaActions";
import ActionMenu from "../../actionmenu/Actionmenu";
import BreadcrumbIndex from "../../breadcrumb/BreadcrumbIndex";
import AreaListTable from "./AreaList";

export default function CityIndex() {
  const areaList = useSelector((state) => state.area.areaList);
  const dispatch = useDispatch();

  const country = localStorage.getItem("country");
  const province = localStorage.getItem("province");
  const city = localStorage.getItem("city");

  const breadcrumb = [
    { title: "Home", url: "/mapper/country" },
    { title: country, url: "/mapper/province" },
    { title: province, url: "/mapper/city" },
    { title: city, url: "/mapper/area" },
  ];
  localStorage.setItem("breadcrumb", JSON.stringify(breadcrumb));

  useEffect(() => {
    const country = localStorage.getItem("country");
    const provinceId = localStorage.getItem("provinceId");
    const cityId = localStorage.getItem("cityId");
    dispatch(loadArea(country, provinceId, cityId));
  }, [dispatch]);

  return (
    <div>
      <BreadcrumbIndex />
      <ActionMenu />
      <AreaListTable tabaledata={areaList} />
    </div>
  );
}
