import {
  Fab,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: "90%",
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 500,
  },
  closeIcon: {
    float: "right",
  },
}));
export default function UploadSummary(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const tabledoby = () => {
    if (props.tabaledata.data && props.tabaledata.data.length > 0) {
      return props.tabaledata.data.map((data) => (
        <TableRow key={data.id} hover root>
          {props.tableheader.map((cell, index) => (
            <TableCell align="left" key={index}>
              {cell.type === "switch" ? data[cell.key] + "" : data[cell.key]}
            </TableCell>
          ))}
        </TableRow>
      ));
    } else {
      <div>No data found</div>;
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Fab
            color="primary"
            size="small"
            onClick={props.close}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </Fab>
          <Typography variant="subtitle2">
            <strong>Upload Summary</strong>
          </Typography>
          <Grid item md={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item md={4}>
                <Typography variant="subtitle2">
                  Total Record Uploaded : {props.tabaledata.total}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Success Record : {props.tabaledata.success}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" color="error">
                  Failed Record : {props.tabaledata.failed}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Typography variant="subtitle2">
            <strong>Failed Record Details</strong>
          </Typography>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {props.tableheader.map((row) => (
                    <StyledTableCell align="left" key={row.name}>
                      {row.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tablebody}>{tabledoby()}</TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
}
