import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { baseUrl, setCurrentUser } from "../../util/host";
import { hideSpinner, showSpinner } from "../../store/spinner/spinnerActions";
import { useDispatch } from "react-redux";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  form: {
    boxSizing: "border-box",
    width: "500px",
    textAlign: "center",
    margin: theme.spacing(0, "auto"),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
}));

const OTPForm = () => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    otp: "",
  });

  const [errors, setErrors] = useState({
    otp: "",
  });

  useEffect(() => {
    // Check if user info exists in localStorage when component mounts
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      history.push("/login");
      // User info not found, redirect to login
      // navigate('/login');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate OTP format (only numeric characters)
    const numericRegex = /^[0-9]*$/;
    if (!numericRegex.test(value)) {
      setErrors({
        ...errors,
        [name]: "Please enter only numeric characters.",
      });
    } else {
      setErrors({
        ...errors,
        [name]: "", // Clear error message if format is correct
      });
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const verifyOTP = async (otp, email, domain) => {
    const result = await axios.post(
      `${baseUrl("REACT_APP_AUTH_SERVICE_URL")}/verify-otp`,
      {
        otp,
        email,
        domain,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("result:::", result.data);
    return result.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Perform validation
    if (values.otp.length === 0) {
      setErrors({
        ...errors,
        otp: "Please enter your OTP",
      });
      return;
    } else if (values.otp.length < 6) {
      setErrors({
        ...errors,
        otp: "OTP must be 6 characters long",
      });
      return;
    }

    let response = {};
    try {
      const email = localStorage.getItem("email");
      const domain = new URL(process.env.REACT_APP_API_URL).hostname;
      dispatch(showSpinner());
      response = await verifyOTP(values.otp, email, domain);
      if (response.status) {
        const userData = response.data.userData;
        setCurrentUser(userData);
        // Set localStorage items
        localStorage.removeItem("email");
        history.push("/mapper/country", { replace: true });
        axios.defaults.headers["Authorization"] = `Bearer ${userData?.idToken}`;
      }
      dispatch(hideSpinner());
    } catch (error) {
      console.error("Error verifying OTP:", error);
      // Handle API call error
      dispatch(hideSpinner());
      setErrors({
        ...errors,
        otp: "OTP entered is wrong",
      });
    }
  };

  const isSubmitDisabled = Boolean(errors.otp) || values.otp === "";

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.form}>
        <Typography color="textPrimary" variant="h5" className={classes.header}>
          Verify OTP
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          Please enter the OTP sent to your registered EMAIL ID.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            error={Boolean(errors.otp)}
            fullWidth
            helperText={errors.otp}
            label="Enter OTP"
            margin="normal"
            name="otp"
            onChange={handleChange}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            value={values.otp}
            variant="outlined"
          />

          <Box mt={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={isSubmitDisabled}
            >
              Verify OTP
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default OTPForm;