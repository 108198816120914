import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  accordion: {
    width: "100%",
  },
}));

const validationSchemaNew = Yup.object({
  name_en: Yup.string().required("Name En required"),
  name_ar: Yup.string().required("Name Ar required"),
  min_sla: Yup.number().min(0).required("Min SLA required"),
  max_sla: Yup.number()
    .min(0)
    .required("Max SLA required")
    .when("$max_sla", (max_sla, schema) => {
      return schema.min(
        Yup.ref("min_sla"),
        "Value must be grater than or equal to Min SLA"
      );
    }),
  threshold: Yup.number()
    .min(0)
    .required("Max SLA required")
    .when("cod_verification", {
      is: true,
      then: Yup.number().min(1, "Threashold must be grater than 0."),
    }),
  max_threshold: Yup.number()
    .min(0)
    .required("Max Threshold required")
    .when("$max_threshold", (max_threshold, schema) => {
      return schema.min(
        Yup.ref("threshold"),
        "Value must be grater than or equal to Min Threshold"
      );
    }),
});

const validationSchema = Yup.object({
  name_en: Yup.string().required("Name En required"),
  name_ar: Yup.string().required("Name Ar required"),
});

export default function ManageArea(props) {
  const isGulf = localStorage.getItem("region") === "GULF";

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const formik = useFormik({
    initialValues: {
      id: props.data.id,
      name_en: props.data.name_en,
      name_ar: props.data.name_ar,
      enabled: props.data.enabled,
      min_sla: props.data.min_sla,
      max_sla: props.data.max_sla,
      fast_delivery: props.data.fast_delivery,
      cod_verification: props.data.cod_verification,
      threshold: props.data.threshold,
      max_threshold: props.data.max_threshold,
      type: "area", //To match with backend DTO
    },
    validationSchema: isGulf ? validationSchema : validationSchemaNew,
    onSubmit: (values) => {
      props.actionHandler(values);
    },
  });

  const options = () => (
    <Grid item md={12}>
      <Grid container spacing={3} display="flex" alignItems="center">
        <Grid item md={3}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            id="min_sla"
            name="min_sla"
            label="Min SLA"
            type="number"
            value={formik.values.min_sla}
            onChange={formik.handleChange}
            error={formik.touched.min_sla && Boolean(formik.errors.min_sla)}
            helperText={formik.touched.min_sla && formik.errors.min_sla}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            id="max_sla"
            name="max_sla"
            label="Max SLA"
            type="number"
            value={formik.values.max_sla}
            onChange={formik.handleChange}
            error={formik.touched.max_sla && Boolean(formik.errors.max_sla)}
            helperText={formik.touched.max_sla && formik.errors.max_sla}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            id="threshold"
            name="threshold"
            label="Min Threshold"
            type="number"
            value={formik.values.threshold}
            onChange={formik.handleChange}
            error={formik.touched.threshold && Boolean(formik.errors.threshold)}
            helperText={formik.touched.threshold && formik.errors.threshold}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            id="max_threshold"
            name="max_threshold"
            label="Max Threshold"
            type="number"
            value={formik.values.max_threshold}
            onChange={formik.handleChange}
            error={
              formik.touched.max_threshold &&
              Boolean(formik.errors.max_threshold)
            }
            helperText={
              formik.touched.max_threshold && formik.errors.max_threshold
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h1>{props.headerMessage}</h1>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <TextField
              id="id"
              name="id"
              type="hidden"
              value={formik.values.id}
              onChange={formik.handleChange}
            />

            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              id="name_en"
              name="name_en"
              label="Name En"
              value={formik.values.name_en}
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue(`name_en`, value);
                if (!isGulf) formik.setFieldValue(`name_ar`, value);
              }}
              error={formik.touched.name_en && Boolean(formik.errors.name_en)}
              helperText={formik.touched.name_en && formik.errors.name_en}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              id="name_ar"
              name="name_ar"
              label="Name Ar"
              value={formik.values.name_ar}
              onChange={formik.handleChange}
              error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
              helperText={formik.touched.name_ar && formik.errors.name_ar}
            />

            <Grid container spacing={2}>
              {!isGulf ? options() : ""}
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.enabled}
                      name="enabled"
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  label="Enabled"
                />
              </Grid>
              {!isGulf ? (
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.cod_verification}
                        name="cod_verification"
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="COD Verificaion"
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            <Grid container spacing={2} justify="flex-end">
              <Grid item md={3}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button variant="contained" fullWidth onClick={props.close}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
}
