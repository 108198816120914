import Axios from "axios";
import { baseUrl } from "../../util/host";

export default async function fetchFastDeliveryPayment() {
  try {
    const apiResponse = await Axios.get(
      `${baseUrl("REACT_APP_API_URL")}/api/address/fastDelivery`
    );
    const { statusCode, response } = (apiResponse && apiResponse.data) || {};
    let msg = `Fast delivery payments fetched`;
    if (statusCode === 404) {
      msg = `Fast delivery payments not saved yet`;
    }
    return { statusCode, data: response, msg };
  } catch (e) {
    console.error(e);
    if(e.response?.status === 403){
      return { statusCode: 403, data: [], msg: e.message };
    }
    return { statusCode: 500, data: [], msg: e.message };
  }
}

export const updateFastDeliveryPayment = async ({
  paymentData,
  selectedTime,
}) => {
  try {
    const apiResponse = await Axios.post(
      `${baseUrl("REACT_APP_API_URL")}/api/address/fastDelivery`,
      { paymentData, selectedTime }
    );
    const { statusCode, response } = (apiResponse && apiResponse.data) || {};
    return {
      statusCode,
      data: response,
      msg: `Fast delivery payments updated`,
    };
  } catch (e) {
    console.error(e);
    if(e.response?.status === 403){
      return { statusCode: 403, data: [], msg: e.message };
    }
    return { statusCode: 500, data: [], msg: e.message };
  }
};
