import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import ActivityLogDetails from "./ActivityLogDetails";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ActivityLogList(props) {
  const classes = useStyles();

  const [modalStatus, setModalStatus] = React.useState(false);
  const [modalValue, setModalValue] = React.useState(null);

  const onRowSelected = (data) => {
    setModalStatus(true);
    setModalValue(data);
  };

  const modalBody = () =>
    modalStatus ? (
      <ActivityLogDetails
        open={modalStatus}
        close={() => setModalStatus(false)}
        data={modalValue}
      />
    ) : (
      ""
    );

  const tabledoby = () => {
    if (props.tabledata) {
      return props.tabledata.map((data) => (
        <StyledTableRow key={data.id} hover onClick={() => onRowSelected(data)}>
          {props.tableheader.map((cell, index) => (
            <TableCell align="left" key={index}>
              {cell.type === "date" ? (
                <Moment format="YYYY-MM-DD hh:mm a">{data[cell.key]}</Moment>
              ) : (
                data[cell.key]
              )}
            </TableCell>
          ))}
        </StyledTableRow>
      ));
    } else {
      return (
        <React.Fragment>
          <div>No data found</div>
        </React.Fragment>
      );
    }
  };
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.tableheader.map((row) => (
                <StyledTableCell align="left" key={row.key}>
                  {row.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tabledoby()}</TableBody>
        </Table>
      </TableContainer>
      {modalBody()}
    </React.Fragment>
  );
}
