export const ProvinceTableHeader = [
  { name: "Code", key: "code", type: "text" },
  { name: "Name", key: "name", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
];

export const ProvinceUploadSummaryTableHeader = [
  { name: "Code", key: "code", type: "text" },
  { name: "Name", key: "name", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "Error Message", key: "message", type: "text" },
];

export const AreaTableHeader = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
];

export const AreaUploadSummaryTableHeader = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Error Message", key: "message", type: "text" },
];

export const CityTableHeader = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "COD", key: "cod_verification", type: "switch" },
  { name: "Threshold", key: "threshold", type: "text" },
];

export const CityUploadSummaryTableHeader = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "COD", key: "cod_verification", type: "switch" },
  { name: "Threshold", key: "threshold", type: "text" },
  { name: "Error Message", key: "message", type: "text" },
];
