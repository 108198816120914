import * as spinnerActionTypes from "./spinnerActionTypes";

export const showSpinner = () => {
  return {
    type: spinnerActionTypes.SHOW_SPINNER,
  };
};
export const hideSpinner = () => {
  return {
    type: spinnerActionTypes.HIDE_SPINNER,
  };
};
