import Axios from "axios";
import { baseUrl } from "../../util/host";
import * as changeLogActionTypes from "./changeLogActionTypes";

export const setChangeLogList = (logList) => {
  return {
    type: changeLogActionTypes.LOAD_CHANGELOG,
    value: logList,
  };
};

export const setPublishHistory = (list) => {
  return {
    type: changeLogActionTypes.LOAD_PUBLISH_HISTORY,
    value: list,
  };
};

const clearPublishHistory = (data) => {
  return {
    type: changeLogActionTypes.DELETE_PUBLISH_HISTORY,
    value: data,
  };
};

export const loadChageLogs = () => {
  return (dispatch) => {
    Axios.get(`${baseUrl("REACT_APP_API_URL")}/api/address/changelog`)
      .then((res) => {
        dispatch(setChangeLogList(res.data.response.changes));
        dispatch(loadValidationMessage());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

/**
 * Load published history details
 */
export const loadPublishedHistory = () => {
  return (dispatch) => {
    Axios.get(`${baseUrl("REACT_APP_API_URL")}/api/address/publish/history`)
      .then((res) => {
        dispatch(setPublishHistory(res.data.response));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const loadPublishedHistoryByTag = (tagdate) => {
  return (dispatch) => {
    Axios.post(
      `${baseUrl("REACT_APP_API_URL")}/api/address/publish/history/tag`,
      {
        tagdate,
      }
    )
      .then((res) => {
        dispatch(setPublishHistory(res.data.response));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const clearPublishedHistoryByTag = (data) => {
  return (dispatch) => {
    Axios.post(
      `${baseUrl("REACT_APP_API_URL")}/api/address/publish/history/clear`,
      {
        tagdate: data.publishedon,
      }
    )
      .then((res) => {
        dispatch(clearPublishHistory(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const clearChangeLogs = () => {
  return {
    type: changeLogActionTypes.CLEAR_CHANGELOG,
    value: [],
  };
};

export const setValidationMessage = (validations) => {
  return {
    type: changeLogActionTypes.LOAD_VALIDATION_MESSAGE,
    value: validations,
  };
};
/**
 * Load validation errors
 */
export const loadValidationMessage = () => {
  return (dispatch) => {
    Axios.get(`${baseUrl("REACT_APP_API_URL")}/api/address/validate`)
      .then((res) => {
        dispatch(setValidationMessage(res.data.response));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
