import { Redirect, Route } from "react-router-dom";
import { getCurrentUser } from "./host";

export default function PrivateRoute({ component: Component, ...properties }) {
  const user  = getCurrentUser();
  const token= user?.token || '';
  const path = properties.location.pathname;

  const renderPath = (props) => {
    if (token && path !== "/") return <Component {...props} />;
    else if (token && path === "/") return <Redirect to="/mapper/country" />;
    else return <Redirect to="/login" />;
  };
  return <Route {...properties} render={(props) => renderPath(props)} />;
}
