import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    //width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonArea: {
    "& > *": {
      margin: theme.spacing(1),
      float: "right",
    },
  },
}));

export default function DeleteModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const validations = useSelector((state) => state.changelog.validations);
  const validationBody = () => {
    const default_city = validations?.default_city_not_set?.toString();
    const sla = validations?.sla_not_set?.map((e) => {
      return <div>- {e}</div>;
    });
    const disabled_default_city = validations?.default_city_disabled?.map(
      (e) => {
        return <div>- {e}</div>;
      }
    );
    const city_body = default_city ? (
      <Alert severity="warning">
        You can't publish the changes, as <strong>default city</strong> is not
        set for country: {default_city}
      </Alert>
    ) : (
      ""
    );
    const slabody =
      validations?.sla_not_set?.length > 0 ? (
        <Alert severity="warning">
          You can't publish the changes, as <strong>MIN/MAX SLA</strong> is not
          set for {sla}
        </Alert>
      ) : (
        ""
      );
    const default_city_disabled =
      validations?.default_city_disabled?.length > 0 ? (
        <Alert severity="warning">
          Following <strong>default city</strong> are disabled. Please enable it
          or set another city as default. : {disabled_default_city}
        </Alert>
      ) : (
        ""
      );
    return (
      <Typography variant="caption" display="block" gutterBottom>
        <Typography variant="caption" display="block" gutterBottom>
          {city_body}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {slabody}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {default_city_disabled}
        </Typography>
      </Typography>
    );
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Publish Confirmation.</h2>
      <p id="simple-modal-description">Do you want to publish the changes?</p>
      {validationBody()}
      <div className={classes.buttonArea}>
        <Button variant="contained" onClick={props.close}>
          Cancel
        </Button>
        <Button
          disabled={
            validations?.default_city_not_set?.length > 0 ||
            validations?.sla_not_set?.length > 0 ||
            validations?.default_city_disabled?.length > 0
          }
          variant="contained"
          color="secondary"
          onClick={props.publishConfirmation}
        >
          Publish
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
