import * as cityActionTypes from "./cityActionTypes";

const initialState = {
  cityList: [],
};

const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case cityActionTypes.LOAD_CITY:
      return {
        ...state,
        cityList: action.value,
      };
    case cityActionTypes.CLEAR_CITY:
      return {
        cityList: [],
      };
    default:
      return state;
  }
};

export default cityReducer;
