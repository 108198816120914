import * as authActionTypes from "./authActionTypes";

const initialState = {
  auth_token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.LOAD_AUTH_TOKEN:
      return {
        ...state,
        auth_token: action.value,
      };
    default:
      return state;
  }
};

export default authReducer;
