import * as provinceActionTypes from "./provinceActionTypes";

const initialState = {
  provinceList: [],
};

const provinceReducer = (state = initialState, action) => {
  switch (action.type) {
    case provinceActionTypes.LOAD_PROVINCE:
      return {
        ...state,
        provinceList: action.value,
      };
    default:
      return state;
  }
};

export default provinceReducer;
