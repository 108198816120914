import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import CityIndex from "../content/city/CityIndex";
import AreaIndex from "../content/area/AreaIndex";
import CountryIndex from "../content/country/CountryIndex";
import ProvinceIndex from "../content/province/ProvinceIndex";
import AddressMapperToolbar from "../toolbar/Toolbar";
import axios from "axios";
import { useDispatch } from "react-redux";
import { publishMessage } from "../../store/notification/notificationActions";
// import firebase from "firebase/app";
import ActivityLog from "../content/activitylog/ActivityLog";
import FastDeliveryPayment from "../content/fastDeliveryPayment"
import Serviceability from "../content/serviceability/Serviceablitiy";
import { baseUrl, getCurrentUser,setCurrentUser,removeFromCookie} from "../../util/host";

export default function Home() {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * On Each page load token is beign updated to http interceptor
   */
  // firebase
  //   .auth()
  //   .currentUser?.getIdToken(false)
  //   .then((idToken) => {
  //     axios.defaults.headers["token"] = idToken;
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
  const refreshFirebaseToken = async () => {
    const loggedInUser = getCurrentUser();
    const {token, refreshToken} = loggedInUser;
    const result = await axios.post(
      `${baseUrl("REACT_APP_AUTH_SERVICE_URL")}/regenerate-token`,
      {
        refreshToken
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.trim()}`,
        }
      }
    )
    .then((result) => {
    console.log('authuser',result);
    const authUser = result.data.data;
    let token1;
    if (result.data.status) {
      token1 = authUser.token;
      setCurrentUser(result.data?.data);
    }else{
     token1 = token;
    }
    axios.defaults.headers["Authorization"] =`Bearer ${token1}`
    console.log('axios.defaults.headers["token"]',axios.defaults.headers["token"]);
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        publishMessage({
          message: "Your token expired. Please login again.",
          severity: "error",
        })
      );
      localStorage.clear();
      removeFromCookie();
      history.push("/login");
      return Promise.reject(err);
    });
  }
  useEffect(async() => {
    console.log('useEffect is triggered!');
    // Call refreshFirebaseToken when the component mounts
    await refreshFirebaseToken();
    const intervalId = setInterval(async() => {
      await refreshFirebaseToken(); // Call the function here
    }, 10 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
}, []);
    /**
   * When page reloads components loads earlier than the firebase app initialize, which cause null as token. And makes unauthenticated request.
   * To avoid this data is being read from localstorage
   */
  // if (!firebase.auth().currentUser)
  //   axios.defaults.headers["token"] = localStorage.getItem("idToken");
  const user = getCurrentUser();
  const { token } = user;
  axios.defaults.headers["Authorization"] =`Bearer ${token}`
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        dispatch(
          publishMessage({
            message: "You are not authenticated. Please login again.",
            severity: "error",
          })
        );
        localStorage.clear();
        removeFromCookie();
        history.push("/login");
        return Promise.reject(error);
      } else if (400 === error.response.status) {
        let msg = error?.response?.data?.statusMsg;
        msg = msg ? msg : error.message;
        dispatch(
          publishMessage({
            message: `Something went wrong [ ${error}]. Message: [${JSON.stringify(
              msg
            )}]`,
            severity: "error",
          })
        );
        return Promise.reject(error);
      } else if (403 === error.response.status) {
        let msg = 'You do not have adequate permission to access this Page. Please contact your administrator.';
        // msg = error?.response?.data?.statusMsg || msg;
        dispatch(
          publishMessage({
            message: msg,
            severity: "error",
          })
        );
        return Promise.reject(error);
      } else {
        dispatch(
          publishMessage({
            message: `Something went wrong. [ ${error}]`,
            severity: "error",
          })
        );
        return Promise.reject(error);
      }
    }
  );

  const content = () => (
    <Switch>
      <Route exact path="/mapper/country" component={CountryIndex} />
      <Route exact path="/mapper/province" component={ProvinceIndex} />
      <Route exact path="/mapper/city" component={CityIndex} />
      <Route exact path="/mapper/area" component={AreaIndex} />
      <Route exact path="/publish/activity" component={ActivityLog} />
      <Route exact path="/delivery/payment" component={FastDeliveryPayment} />

      <Route exact path="/serviceability" component={Serviceability} />
    </Switch>
  );
  return (
    <React.Fragment>
      <AddressMapperToolbar />
      {content()}
    </React.Fragment>
  );
}
