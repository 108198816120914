import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Typography } from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonArea: {
    "& > *": {
      margin: theme.spacing(1),
      float: "right",
    },
  },
}));

export default function ChangeLogs(props) {
  const classes = useStyles();
  const province = props.changeLogs.filter((log) => log.type === "province")
    .length;
  const city = props.changeLogs.filter((log) => log.type === "city").length;
  const area = props.changeLogs.filter((log) => log.type === "area").length;

  const [modalStyle] = React.useState(getModalStyle);
  //const changeLogs = useSelector((state) => state.changelog.changeLogs);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Your Changes Counts.</h2>
      <Typography variant="subtitle2" gutterBottom>
        Province : <strong>{province}</strong>
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Cities : <strong>{city}</strong>
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Area : <strong>{area}</strong>
      </Typography>

      <div className={classes.buttonArea}>
        <Button variant="contained" onClick={props.close}>
          Close
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
