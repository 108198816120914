
const subdomain = window.location.hostname;
const subdomainParts = subdomain.split('.');
const domainName = subdomainParts.slice(-2).join('.');
console.log(domainName);

export const baseUrl = (key) => {
  const region = localStorage.getItem("region");

  const url = process.env[key];
  if (url?.includes("localhost")) return url;
  if (region && region !== "GULF") {
    const [protcol, path] = url.split("://");
    return `${protcol}://${region.toLocaleLowerCase()}-${path}`;
  }
  return url;
};


export const getFlag = () => {
  return localStorage.getItem('region') === 'IN'
  ? 'https://storage.googleapis.com/dev-bucket.stylifashion.com/flags/flag_in.png'
  : 'https://storage.googleapis.com/dev-bucket.stylifashion.com/GCC_Flag.svg';
}

export const getPods = () => {
  return localStorage.getItem('region') === 'IN' ? 'IND' : 'GCC';
}

export const removeFromCookie = () => {
  const cookieName = 'styli_sso_user';
  document.cookie = `${cookieName}=; domain=${domainName}; path=/;`;
};

const getCookie = (cookieName, domain) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    // Check if the cookie starts with the specified name
    if (cookie.indexOf(`${cookieName}=`) === 0) {
      // Check if the cookie domain matches the specified domain
      if (domain && cookie.indexOf(`domain=${domain}`) === -1) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  }
  return null;
};

const getJsonCookie = (cookieName, domain) => {
  const cookieValue = getCookie(cookieName, domain);
  if (cookieValue) {
    try {
      // Parse the JSON value from the cookie
      return JSON.parse(decodeURIComponent(cookieValue));
    } catch (error) {
      // Handle parsing errors if necessary
      console.error('Error parsing JSON from cookie:', error);
    }
  }

  return null; // Return null if the cookie or JSON value doesn't exist
};

const setJsonCookie = (cookieName, jsonObject, expirationDays, domain) => {
  const jsonString = JSON.stringify(jsonObject);

  let cookieString = `${cookieName}=${encodeURIComponent(jsonString)}; path=/;`;

  if (expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    cookieString += `; expires=${expirationDate.toUTCString()}`;
  }

  if (domain) {
    cookieString += `; domain=${domain}`;
  }

  document.cookie = cookieString;
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user = getJsonCookie('styli_sso_user', domainName);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  const cookieName = 'styli_sso_user';
  try {
    if (user) {
      setJsonCookie(cookieName, user, 0, domainName);
      // localStorage.setItem('styli_sso_user', JSON.stringify(user));
    } else {
      // localStorage.removeItem('styli_sso_user');
      document.cookie = `${cookieName}=; domain=${domainName}; path=/;`;
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

