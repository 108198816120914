import {
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCountry } from "../../../store/country/countryActions";
import {
  downloadServiceabilityTemplate,
  uploadServiceability,
} from "../../../store/serviceability/serviceabilityActions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    minWidth: 130,
  },
  input: {
    display: "none",
  },
  button_text: {
    textTransform: "initial",
  },
}));
export default function Serviceability() {
  const classes = useStyles();
  const history = useHistory();
  const [country, setCountry] = useState("");
  const [error, setError] = useState(null);
  const countryList = useSelector((state) => state.country.countryList);
  const [filename, setFilename] = React.useState(
    `Click to Upload Serviceability.`
  );
  const [file, setFile] = React.useState("");
  let fileInputRef = useRef();
  const dispatch = useDispatch();

  const goback = () => {
    history.goBack();
  };

  useEffect(() => {
    if (countryList.length === 0) {
      dispatch(loadCountry());
    } else {
      setCountry(countryList[0].title);
    }
  }, [countryList, dispatch]);

  const handleChange = (e) => {
    const country = e.target.value;
    setCountry(country);
    if (file) {
      if (file.name.includes(country)) {
        setError(null);
      } else {
        setError(`Seems file doesn't belongs to country [${country}].`);
      }
    }
  };

  const downloadTemplate = () => {
    dispatch(downloadServiceabilityTemplate(country));
  };

  const uploadFile = () => {
    if (file.name.includes(country)) {
      dispatch(uploadServiceability(country, file));
      setFilename(`Click to Upload Serviceability.`);
      setFile("");
      fileInputRef.current.value = "";
    } else {
      setError(`Seems file doesn't belongs to country [${country}].`);
    }
  };

  const fileChangeHandler = (event) => {
    fileInputRef.current = event.currentTarget;
    const file = event.target.files[0];
    if (file) {
      setError(null);
      setFilename(file.name);
      setFile(file);
      if (!file.name.includes(country)) {
        setError(`Seems file doesn't belongs to country [${country}].`);
      }
    }
  };
  const content = () => (
    <Container maxWidth="xl">
      <br />
      <Paper>
        <Box display="flex" p={1} m={1}>
          <FormControl variant="outlined" className={classes.dropdown}>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              onChange={handleChange}
              label="Country"
            >
              {countryList.map((c, idx) => (
                <MenuItem key={idx} value={c.title}>
                  {c.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {error ? (
          <Box width="90%" p={1} m={1}>
            <Alert severity="error">{error}</Alert>
          </Box>
        ) : (
          ""
        )}
        <Box display="flex" p={1} m={1}>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <input
                accept=".csv"
                className={classes.input}
                id="csv-file-upload"
                type="file"
                onChange={fileChangeHandler}
              />
              <label htmlFor="csv-file-upload">
                <Box display="flex">
                  <Button variant="contained" component="span" color="default">
                    Choose File
                  </Button>
                  <Box
                    p={1}
                    flexGrow={1}
                    style={{ textAlign: "left", cursor: "pointer" }}
                    bgcolor="grey.100"
                  >
                    {filename}
                  </Box>
                </Box>
              </label>
            </Grid>
            <Grid item sm={4}>
              <Box
                p={1}
                flexGrow={1}
                style={{ cursor: "pointer" }}
                onClick={downloadTemplate}
              >
                <strong
                  style={{ color: "green" }}
                >{`Download Template for ${country}.`}</strong>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" p={1} m={1}>
          <Box display="flex">
            <Button
              variant="contained"
              className={classes.button_text}
              color="primary"
              disabled={!file || error}
              onClick={uploadFile}
            >
              {`Upload for ${country}`}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );

  return (
    <React.Fragment>
      <br />
      <Grid container>
        <Grid item md={1}>
          <Fab
            color="primary"
            aria-label="edit"
            size="small"
            variant="extended"
            onClick={goback}
          >
            <ArrowBackIcon />
          </Fab>
        </Grid>
      </Grid>

      {content()}
    </React.Fragment>
  );
}
