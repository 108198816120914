import {
  Fab,
  Grid,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import CloseIcon from "@material-ui/icons/Close";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    //width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonArea: {
    "& > *": {
      margin: theme.spacing(1),
      float: "right",
    },
  },
}));

export default function ActivityLogDetails(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const tabledoby = () => {
    let changes = [];
    const meta = [
      "action",
      "country",
      "created",
      "publishedby",
      "publishedon",
      "sub_type",
      "user",
      "bucket_id",
      "type",
      "published",
      "province",
      "city",
    ];
    if (props.data.action === "create") {
      Object.keys(props.data).forEach((key) => {
        if (!meta.includes(key)) {
          let value = {};
          value["name"] = key;
          value["oldvalue"] = props.data[key];
          value["newvalue"] = props.data[key];
          changes.push(value);
        }
      });
    } else {
      Object.keys(props.data).forEach((key) => {
        if (key.includes("_new")) {
          const oldKey = key.substring(0, key.indexOf("_new"));
          let value = {};
          value["name"] = oldKey;
          value["oldvalue"] = props.data[oldKey];
          value["newvalue"] = props.data[key];
          changes.push(value);
        }
      });
    }

    return changes.map((change) => (
      <TableRow key={change.name}>
        <TableCell>{change.name}</TableCell>
        <TableCell>
          {typeof change.oldvalue === "boolean"
            ? change.oldvalue + "" //convert boolean to string for display
            : change.oldvalue}
        </TableCell>
        <TableCell>
          {typeof change.newvalue === "boolean"
            ? change.newvalue + "" //convert boolean to string for display
            : change.newvalue}
        </TableCell>
      </TableRow>
    ));
  };
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Fab
          color="primary"
          aria-label="close"
          size="small"
          onClick={props.close}
          style={{ float: "right" }}
        >
          <CloseIcon />
        </Fab>
        <h2 id="simple-modal-title">Activity Details.</h2>

        <Grid container>
          <Grid item sm={3}>
            <Typography variant="subtitle2">
              <strong>Section</strong>
            </Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography variant="subtitle2">
              <strong>:</strong> {props.data.type}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle2">
              <strong>Date</strong>
            </Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography variant="subtitle2">
              <strong>:</strong>{" "}
              <Moment format="DD-MM-YYYY hh:mm a">{props.data.created}</Moment>
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle2">
              <strong>Name</strong>
            </Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography variant="subtitle2">
              <strong>:</strong>{" "}
              {props.data.type === "province"
                ? props.data.name
                : props.data.name_en}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle2">
              <strong>Changed By</strong>
            </Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography variant="subtitle2">
              <strong>:</strong> {props.data.user}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="subtitle2">
              <strong>Action Type</strong>
            </Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography variant="subtitle2">
              <strong>:</strong> {props.data.action}
            </Typography>
          </Grid>
        </Grid>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Field Name</strong>
              </TableCell>
              <TableCell>
                <strong>Old Value</strong>
              </TableCell>
              <TableCell>
                <strong>New Value</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tabledoby()}</TableBody>
        </Table>
      </div>
    </Modal>
  );
}
