import { publishMessage } from "../notification/notificationActions";
import * as areaActionTypes from "./areaActionTypes";
import * as message from "../../message";
import { hideSpinner, showSpinner } from "../spinner/spinnerActions";
import Axios from "axios";
import { baseUrl } from "../../util/host";

export const setAreaList = (areas) => {
  return {
    type: areaActionTypes.LOAD_AREA,
    value: areas,
  };
};

export const loadArea = (country, provinceId, cityId) => {
  const payload = { country: country, provinceId, cityId };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(`${baseUrl("REACT_APP_API_URL")}/api/address/find/area`, payload)
      .then((res) => {
        dispatch(setAreaList(res.data.response));
        dispatch(
          publishMessage({
            message: message.LOAD_AREA_SUCCESS,
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideSpinner());
      });
  };
};

export const updateArea = (area) => {
  const country = localStorage.getItem("country");
  const provinceId = localStorage.getItem("provinceId");
  const cityId = localStorage.getItem("cityId");
  const payload = { objkey: country, country, provinceId, cityId, area };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.put(`${baseUrl("REACT_APP_API_URL")}/api/address/area`, payload)
      .then((res) => {
        dispatch(loadArea(country, provinceId, cityId));
        dispatch(
          publishMessage({
            message: "Area Loaded",
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideSpinner());
      });
  };
};

export const addNewArea = (area) => {
  const country = localStorage.getItem("country");
  const provinceId = localStorage.getItem("provinceId");
  const cityId = localStorage.getItem("cityId");
  const payload = { objkey: country, country, provinceId, cityId, area };
  return (dispatch) => {
    dispatch(showSpinner());
    Axios.post(`${baseUrl("REACT_APP_API_URL")}/api/address/area`, payload)
      .then((res) => {
        dispatch(loadArea(country, provinceId, cityId));
        dispatch(
          publishMessage({
            message: "Area Loaded",
            severity: "success",
          })
        );
        dispatch(hideSpinner());
      })
      .catch((err) => {
        console.log(err);
        dispatch(hideSpinner());
      });
  };
};
