import * as changeLogActionTypes from "./changeLogActionTypes";

const initialState = {
  changeLogs: [],
  hasChanges: false,
  validations: [],
  publishedlog: [],
  publishedtags: [],
};

const loadChangesLog = (state, action) => {
  return {
    ...state,
    changeLogs: action.value,
    hasChanges: action.value.length > 0 ? true : false,
  };
};
const loadPublishedHistory = (state, action) => {
  return {
    ...state,
    publishedlog: action.value.changes,
    publishedtags: action.value.publishedtags,
  };
};

const deletePublishedHistory = (state, action) => {
  const newtags = state.publishedtags.filter((t) => t.tag !== action.value.tag);
  return {
    ...state,
    publishedtags: Object.assign([], newtags),
    publishedlog: [],
  };
};

const changesLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case changeLogActionTypes.LOAD_CHANGELOG:
      return loadChangesLog(state, action);
    case changeLogActionTypes.CLEAR_CHANGELOG:
      return { changeLogs: [], hasChanges: false };
    case changeLogActionTypes.LOAD_VALIDATION_MESSAGE:
      return { ...state, validations: action.value };
    case changeLogActionTypes.LOAD_PUBLISH_HISTORY:
      return loadPublishedHistory(state, action);
    case changeLogActionTypes.DELETE_PUBLISH_HISTORY:
      return deletePublishedHistory(state, action);

    default:
      return state;
  }
};
export default changesLogReducer;
