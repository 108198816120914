import { hideSpinner, showSpinner } from "../spinner/spinnerActions";
import axios from "axios";
import * as message from "../../message";
import { publishMessage } from "../notification/notificationActions";
import { baseUrl } from "../../util/host";

export const downloadServiceabilityTemplate = (value) => {
  const payload = { country: value };
  const date = new Date();
  const postfix = date.toISOString().replace("-", "_");
  return (dispatch) => {
    dispatch(showSpinner());
    axios
      .post(
        `${baseUrl("REACT_APP_API_URL")}/api/address/serviceable/template`,
        payload
      )
      .then((res) => {
        const file = res.data;
        const blob = new Blob([file], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("hidden", true);
        a.setAttribute("href", url);
        a.setAttribute("download", `serviceability_${value}_${postfix}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch(hideSpinner());
      })
      .catch((err) => {
        dispatch(hideSpinner());
      });
  };
};

export const uploadServiceability = (value, file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("country", value);
  return (dispatch) => {
    dispatch(showSpinner());
    axios
      .post(
        `${baseUrl("REACT_APP_API_URL")}/api/address/serviceable/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        }
      )
      .then((res) => {
        dispatch(hideSpinner());
        if (res.status === 200)
          dispatch(
            publishMessage({
              message: message.SERVICEABILITY_UPLOADED,
              severity: "success",
            })
          );
      })
      .catch((err) => {
        dispatch(hideSpinner());
        dispatch(
          publishMessage({
            message: err.message,
            severity: "success",
          })
        );
      });
  };
};
