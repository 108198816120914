import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { hideNotification } from "../../store/notification/notificationActions";

export default function ActionNotification() {
  const dispatch = useDispatch();
  const [state] = React.useState({
    vertical: "top",
    horizontal: "right",
    alertHideDuration: 4000,
  });

  const { vertical, horizontal } = state;
  const notifications = useSelector(
    (state) => state.notification.notificationObj
  );
  const hideNotificationMessage = (e, idx) => {
    dispatch(hideNotification(idx));
  };

  const content = () => {
    const res =
      notifications && notifications.length > 0
        ? notifications.map((notification, idx) => (
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={true}
              key={idx}
              autoHideDuration={state.alertHideDuration}
              onClose={(e) => hideNotificationMessage(e, idx)}
            >
              <Alert
                severity={notification.severity}
                onClose={(e) => hideNotificationMessage(e, idx)}
              >
                {notification.message}
              </Alert>
            </Snackbar>
          ))
        : "";

    return res;
  };

  return content();
}
