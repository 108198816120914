import {
  Fab,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPublishedHistory,
  loadPublishedHistoryByTag,
} from "../../../store/changelog/changeLogActions";
import ActivityLogList from "./ActivityLogList";
import Moment from "react-moment";

const tableheader = [
  { name: "Updated At", key: "created", type: "date" },
  { name: "User", key: "user", type: "text" },
  { name: "Country", key: "country", type: "text" },
  { name: "Province", key: "province", type: "text" },
  { name: "City", key: "city", type: "text" },
  { name: "Name", key: "name_en", type: "text" },
  { name: "Changes In", key: "type", type: "text" },
];

export default function ActivityLog() {
  const history = useHistory();
  const dispatch = useDispatch();
  const publishedlog = useSelector((state) => state.changelog.publishedlog);
  const publishedtags = useSelector((state) => state.changelog.publishedtags);
  const [state, setState] = useState({
    tag: null,
    publishedby: null,
  });
  const goback = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(loadPublishedHistory());
  }, [dispatch]);

  const onChangeTag = (e) => {
    const filterValue = publishedtags.find((tag) => tag.tag === e.target.value);
    setState({
      ...state,
      tag: e.target.value,
      publishedby: filterValue.publishedby,
    });
    dispatch(loadPublishedHistoryByTag(filterValue.publishedon));
  };

  /* const onClearHistory = () => {
    const tag = state.tag ? state.tag : publishedtags[0].tag;
    const filterValue = publishedtags.find((r) => r.tag === tag);
    dispatch(clearPublishedHistoryByTag(filterValue));
    setState({ tag: "Please Select", publishedby: null });
  }; */

  const logcontent = () => {
    if (publishedtags?.length > 0) {
      return (
        <>
          <Grid container>
            <Grid item sm={4}>
              <strong>Published On : </strong>
              <Select
                labelId="select_tag"
                id="select_tag"
                value={state.tag ? state.tag : publishedtags[0].tag}
                onChange={onChangeTag}
              >
                {publishedtags.map((tag) => (
                  <MenuItem value={tag.tag} key={tag.tag}>
                    <Moment format="DD-MM-YYYY hh:mm a">
                      {tag.publishedon}
                    </Moment>
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item sm={3}>
              <Typography variant="subtitle2">
                <strong>Published By : </strong>{" "}
                {state.publishedby
                  ? state.publishedby
                  : publishedtags[0].publishedby}
              </Typography>
            </Grid>
            {/* <Grid item sm={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClearHistory}
              >
                Clear History
              </Button>
            </Grid> */}
          </Grid>
          <br />
          <ActivityLogList tableheader={tableheader} tabledata={publishedlog} />
        </>
      );
    } else {
      return (
        <Paper variant="outlined">
          <br />
          <Typography variant="subtitle2" gutterBottom>
            <strong>
              There is nothing publised yet or logs has been cleared.
            </strong>
          </Typography>
          <br />
        </Paper>
      );
    }
  };

  return (
    <React.Fragment>
      <br />
      <Grid container>
        <Grid item md={1}>
          <Fab
            color="primary"
            aria-label="edit"
            size="small"
            variant="extended"
            onClick={goback}
          >
            <ArrowBackIcon />
          </Fab>
        </Grid>
      </Grid>

      {logcontent()}
    </React.Fragment>
  );
}
