import * as breadcrumbActionTypes from "./breadcrumbActionTypes";

const initialState = {
  breadcrumbs: [{ title: "Home", url: "/mapper/country" }],
};

const addNewItemToBreadcrumb = (state, action) => {
  return {
    breadcrumbs: state.breadcrumbs.concat(action.value),
  };
};

const removeLastItemFromBreadcrumb = (state, action) => {
  let filter = state.breadcrumbs.filter((b) => b.title === action.value.title);
  let index = state.breadcrumbs.indexOf(filter[0]);
  let breadcumb = state.breadcrumbs.slice(0, index + 1);
  return {
    breadcrumbs: breadcumb,
  };
};

const breadcrumbReducer = (state = initialState, action) => {
  switch (action.type) {
    case breadcrumbActionTypes.ADD_ITEM:
      return addNewItemToBreadcrumb(state, action);
    case breadcrumbActionTypes.REMOVE_ITEM:
      return removeLastItemFromBreadcrumb(state, action);
    default:
      return state;
  }
};

export default breadcrumbReducer;
