import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const addressMapperStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default addressMapperStore;
