import * as countryActionTypes from "./countryActionTypes";

const initialState = {
  countryList: [],
  error: null,
};

const loadCountry = (state, action) => {
  return {
    ...state,
    countryList: action.value,
  };
};

const addCountry = (state, action) => {
  return {
    ...state,
    countryList: state.countryList.concat(action.value),
  };
};

const failedToLoadCountry = (state, action) => {
  return {
    countryList: [],
    error: action.value,
  };
};

const updateCutOff = (state, action) => {
  const title = action?.value?.title;
  const newCountryList = state.countryList.map((c) => {
    if (c.title === title && action?.value?.type === "time") {
      c.cut_off_time = action?.value?.type_data;
    } else if (c.title === title && action?.value?.type === "day") {
      c.holiday = action?.value?.type_data;
    } else if (c.title === title && action?.value?.type === "disable") {
      c.cut_off_time = "";
    }
    return c;
  });

  return {
    ...state,
    countryList: newCountryList,
  };
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case countryActionTypes.LOAD_COUNTRY:
      return loadCountry(state, action);
    case countryActionTypes.ADD_COUNTRY:
      return addCountry(state, action);
    case countryActionTypes.LOAD_COUNTRY_FAILED:
      return failedToLoadCountry(state, action);
    case countryActionTypes.UPDATE_CUTOFF_TIME:
      return updateCutOff(state, action);
    default:
      return state;
  }
};

export default countryReducer;
