import * as notificationActionTypes from "./notificationActionTypes";

const initialState = {
  notificationObj: [],
};

const addNotification = (state, action) => {
  const notifyObj = {};
  notifyObj["message"] = action.value.message;
  notifyObj["severity"] = action.value.severity;
  return { ...state, notificationObj: state.notificationObj.concat(notifyObj) };
};

const removeNotification = (state, action) => {
  state.notificationObj.splice(action.value, 1);
  let newNotificationObj = [];
  Object.assign(newNotificationObj, state.notificationObj);
  return {
    notificationObj: newNotificationObj,
  };
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActionTypes.PUSH_MESSAGE:
      return addNotification(state, action);
    case notificationActionTypes.HIDE_NOTIFICATION:
      return removeNotification(state, action);
    /*  return {
        message: "",
        severity: "success",
        //notificationObj: state.notificationObj.filter((n,idx) => idx !== action.value),
        shownotification: false,
      }; */
    default:
      return state;
  }
};

export default notificationReducer;
