import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteModal from "../../ui/DeleteModal";
import { useDispatch } from "react-redux";
import { updateArea, addNewArea } from "../../../store/area/areaActions";
import AddIcon from "@material-ui/icons/Add";
import ManageArea from "./ManageArea";
import { Container, Fab, Typography } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  createButton: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(4),
  },
  tableContainer: {
    maxHeight: 600,
  },
}));

const tableheader = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
];

const tableheaderNew = [
  { name: "Name EN", key: "name_en", type: "text" },
  { name: "Name Ar", key: "name_ar", type: "text" },
  { name: "Enabled", key: "enabled", type: "switch" },
  { name: "Min SLA", key: "min_sla", type: "text" },
  { name: "Max SLA", key: "max_sla", type: "text" },
  { name: "COD Verification", key: "cod_verification", type: "switch" },
  { name: "Min Threshold Amount", key: "threshold", type: "text" },
  { name: "Max Threshold Amount", key: "max_threshold", type: "text" },
];

export default function AreaListTable(props) {
  const isGulf = localStorage.getItem("region") === "GULF";
  const tableHeader = isGulf ? tableheader : tableheaderNew;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState({
    deleteAction: false,
    editAction: false,
    createAction: false,
    selectedItem: null,
    createItem: {
      id: 0,
      name_en: "",
      name_ar: "",
      enabled: true,
      min_sla: 0,
      max_sla: 0,
      fast_delivery: false,
      cod_verification: false,
      threshold: 0,
      max_threshold: 0,
    },
  });

  const editRow = (event, data) => {
    event.stopPropagation();
    setState({
      ...state,
      editAction: true,
      deleteAction: false,
      selectedItem: data,
    });
  };

  const closeModal = (name) => {
    setState({ ...state, [name]: false });
  };

  const updateAreaHandler = (area) => {
    dispatch(updateArea(area));
    closeModal("editAction");
  };

  const createAreaAction = (area) => {
    dispatch(addNewArea(area));
    closeModal("createAction");
  };

  const openCreateModal = () => {
    setState({ ...state, createAction: true });
  };
  const modalPopUp = () => {
    if (state.deleteAction) {
      return (
        <DeleteModal
          open={state.deleteAction}
          close={() => closeModal("deleteAction")}
          title={state.selectedItem.title}
          deleteConfirmation={props.deleteAPICall}
        />
      );
    } else if (state.editAction) {
      return (
        <ManageArea
          headerMessage="Update Area/Pincode"
          open={state.editAction}
          close={() => closeModal("editAction")}
          data={state.selectedItem}
          actionHandler={updateAreaHandler}
        />
      );
    } else if (state.createAction) {
      return (
        <ManageArea
          headerMessage="Add New Area/Pincode"
          open={state.createAction}
          close={() => closeModal("createAction")}
          data={state.createItem}
          actionHandler={createAreaAction}
        />
      );
    }
  };

  const tabledoby = () => {
    if (props.tabaledata) {
      return props.tabaledata.map((data) => (
        <StyledTableRow key={data.id} hover>
          {tableHeader.map((cell, index) => (
            <TableCell align="left" key={index}>
              {cell.type === "switch" ? data[cell.key] + "" : data[cell.key]}
            </TableCell>
          ))}
          <TableCell>
            <Fab
              color="default"
              aria-label="edit"
              size="small"
              variant="extended"
              onClick={(event) => editRow(event, data)}
            >
              <EditIcon color="primary" />
              {/* <DeleteIcon onClick={() => deleteRow(data)} color="secondary" /> */}
            </Fab>
          </TableCell>
        </StyledTableRow>
      ));
    } else {
      <div>No data found</div>;
    }
  };
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Fab
          color="primary"
          aria-label="add"
          onClick={openCreateModal}
          className={classes.createButton}
        >
          <AddIcon />
        </Fab>
        <Typography variant="subtitle2" gutterBottom align="left">
          Area/Pincode
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tableHeader.map((row) => (
                  <StyledTableCell align="left" key={row.name}>
                    {row.name}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tabledoby()}</TableBody>
          </Table>
        </TableContainer>
      </Container>
      {modalPopUp()}
    </React.Fragment>
  );
}
