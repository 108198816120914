import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteModal from "../../ui/DeleteModal";
import PublishIcon from "@material-ui/icons/Publish";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  publishCountry,
  updateCutOffTime,
} from "../../../store/country/countryActions";
import logo from "../../../assets/header-color.svg";
import ChangeLogs from "./ChangeLogs";

import DateFnsUtils from "@date-io/date-fns";
import { getHours, getMinutes, setMinutes, setHours } from "date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 110,
  },
});

export default function CountryListTable(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    publishAction: false,
    selectedItem: null,
    openChangeLogs: false,
  });

  const publishAction = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      publishAction: true,
    });
  };

  const openChangeLogs = () => {
    setState({
      ...state,
      openChangeLogs: true,
    });
  };

  const closeModal = (name) => {
    setState({ ...state, [name]: false });
  };

  const publishActionConfirmation = () => {
    dispatch(publishCountry());
    closeModal("publishAction");
  };
  const modalPopUp = () => {
    if (state.publishAction) {
      return (
        <DeleteModal
          open={state.publishAction}
          close={() => closeModal("publishAction")}
          publishConfirmation={publishActionConfirmation}
        />
      );
    } else if (state.openChangeLogs) {
      return (
        <ChangeLogs
          open={state.openChangeLogs}
          close={() => closeModal("openChangeLogs")}
          changeLogs={props.changeLogs}
        />
      );
    }
  };

  const handleTimeChange = async (time, data) => {
    const hours = getHours(time);
    const minutes = getMinutes(time);
    const t = `${hours}:${minutes}`;
    dispatch(await updateCutOffTime(t, data?.title, "time"));
  };

  const handleHolidayChange = async (event, data) => {
    dispatch(await updateCutOffTime(event.target.value, data?.title, "day"));
  };

  const handleSwitchChange = async (e, data) => {
    if (!e.target.checked) {
      dispatch(await updateCutOffTime("", data?.title, "disable"));
    }
  };

  const formatDateTime = (time) => {
    const timeArr = time ? time.split(":") : "00:00";
    return setHours(
      setMinutes(new Date(), Number(timeArr[1])),
      Number(timeArr[0])
    );
  };

  const weekdays_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const checkChecked = (val) => {
    return val ? true : false;
  };

  const getDays = (weekdays_names, selectedDay) => {
    const days = [];
    weekdays_names.forEach((day, i) => {
      if (selectedDay.indexOf(i.toString()) > -1) {
        days.push(day);
      }
    });
    return days;
  };

  const tabledoby = () => {
    if (props.tabaledata) {
      return props.tabaledata.map((data) => (
        <StyledTableRow key={data.title} hover>
          {props.tableheader.map((cell, index) => {
            if (cell.type === "day") {
              const selected_day = data[cell.key]
                ? data[cell.key].split(",")
                : [];
              return (
                <TableCell>
                  <Select
                    multiple
                    value={selected_day}
                    renderValue={(selectedDay) => {
                      const days = getDays(weekdays_names, selectedDay);
                      return days.join(", ");
                    }}
                    onChange={(val) => handleHolidayChange(val, data)}
                  >
                    {weekdays_names.map((v, idx) => {
                      return (
                        <MenuItem
                          key={weekdays_names.indexOf(v).toString()}
                          value={weekdays_names.indexOf(v).toString()}
                        >
                          <Checkbox
                            checked={selected_day.indexOf(idx.toString()) >= 0}
                          />
                          <ListItemText primary={v} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              );
            } else if (cell.type === "timer") {
              const selectedDate = formatDateTime(data[cell.key]);
              return (
                <TableCell>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      value={selectedDate}
                      onChange={(val) => handleTimeChange(val, data)}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
              );
            } else if (cell.type === "switch") {
              let idChecked = checkChecked(data["cut_off_time"]);
              return (
                <TableCell>
                  <Switch
                    checked={idChecked}
                    onChange={(e) => handleSwitchChange(e, data)}
                    value="checkedB"
                    color="primary"
                  />
                </TableCell>
              );
            } else
              return (
                <TableCell
                  align="left"
                  key={index}
                  onClick={() => props.rowSelected(data)}
                >
                  {data[cell.key]}
                </TableCell>
              );
          })}
        </StyledTableRow>
      ));
    } else {
      <div>No data found</div>;
    }
  };
  return (
    <Container maxWidth="xl">
      <Typography variant="subtitle2" gutterBottom align="left">
        Country List
      </Typography>
      <Grid container spacing={2}>
        <Grid item xm={1}>
          <TableContainer component={Paper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {props.tableheader.map((row) => (
                    <StyledTableCell align="left" key={row.name}>
                      {row.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{tabledoby()}</TableBody>
            </Table>
          </TableContainer>
          {modalPopUp()}
        </Grid>
        <Grid item xm={1}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={logo}
                title="Changes Log"
              />
              <CardContent>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  align="left"
                >
                  {props.hasChanges
                    ? "You have few changes pending to be published."
                    : "You don't have any changes yet."}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                disabled={!props.hasChanges}
                onClick={openChangeLogs}
              >
                View Change Details
              </Button>
              <Button
                disabled={!props.hasChanges}
                variant="contained"
                color="secondary"
                onClick={publishAction}
                endIcon={<PublishIcon />}
              >
                Publish
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
