import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadChageLogs } from "../../../store/changelog/changeLogActions";
import { loadCountry } from "../../../store/country/countryActions";

import Country from "./Country";

export default function CountryIndex() {
  const countryList = useSelector((state) => state.country.countryList);
  const hasChanges = useSelector((state) => state.changelog.hasChanges);
  const changeLogs = useSelector((state) => state.changelog.changeLogs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCountry());
    dispatch(loadChageLogs());
  }, [dispatch]);

  return (
    <Country
      countryList={countryList}
      changeLogs={changeLogs}
      hasChanges={hasChanges}
    />
  );
}
