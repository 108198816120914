import * as notificationActionTypes from "./notificationActionTypes";

export const publishMessage = (notification) => {
  return {
    type: notificationActionTypes.PUSH_MESSAGE,
    value: notification,
  };
};
export const hideNotification = (index) => {
  return {
    type: notificationActionTypes.HIDE_NOTIFICATION,
    value: index,
  };
};
