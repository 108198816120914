import * as areaActionTypes from "./areaActionTypes";

const initialState = {
  areaList: [],
};

const areaReducer = (state = initialState, action) => {
  switch (action.type) {
    case areaActionTypes.LOAD_AREA:
      return {
        ...state,
        areaList: action.value,
      };
    default:
      return state;
  }
};

export default areaReducer;
