import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function Spinner() {
  const classes = useStyles();
  const loading = useSelector((state) => state.spinner.loading);

  const spinnerHTML = () => (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  return loading ? spinnerHTML() : "";
}
