import Breadcrumb from "./Breadcrumb";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      //margin: theme.spacing(1),
      //width: "100%",
      height: theme.spacing(5),
    },
  },
  breadcrumbContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

export default function BreadcrumbIndex() {
  const classes = useStyles();

  const history = useHistory();

  const navigateBreadcrumb = (event, row) => {
    event.preventDefault();
    history.push(row.url);
  };

  return (
    <Container maxWidth="xl" className={classes.breadcrumbContainer}>
      <Breadcrumb
        breadcrumb={localStorage.getItem("breadcrumb")}
        navigateBreadcrumb={navigateBreadcrumb}
      />
    </Container>
  );
}
