import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadArea } from "../../store/area/areaActions";
import { loadProvince } from "../../store/province/provinceActions";
import { loadCity } from "../../store/city/cityActions";
import { publishMessage } from "../../store/notification/notificationActions";
import UploadSummary from "./UploadSummary";
import * as TableHeaders from "../util/TableHeaders";
import Container from "@material-ui/core/Container";
import { hideSpinner, showSpinner } from "../../store/spinner/spinnerActions";
import axios from "axios";
import { baseUrl } from "../../util/host";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

export default function ActionMenu() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    openSummaryModal: false,
    uploadSummaryResponse: null,
    page: "",
  });

  const [action, setAction] = React.useState("Create");
  const setActionType = (event, newValue) => {
    setAction(newValue);
  };

  const validateUploadFile = (page, file) => {
    const fileName = buildFileName(page);
    const fileSplitName = file.name.split(" ")[0];
    return fileSplitName === fileName || fileSplitName === fileName + ".csv";
  };

  //File Upload Action
  const uploadFile = (event) => {
    var file = event.target.files[0];
    if (!file) return;
    let validfile = false;
    let page;
    switch (history.location.pathname) {
      case "/mapper/country":
        page = "country";
        validfile = validateUploadFile(page, file);
        break;
      case "/mapper/province":
        page = "province";
        validfile = validateUploadFile(page, file);
        break;
      case "/mapper/city":
        page = "city";
        validfile = validateUploadFile(page, file);
        break;
      case "/mapper/area":
        page = "area";
        validfile = validateUploadFile(page, file);
        break;
      default:
        break;
    }
    event.target.value = null;
    if (validfile) {
      setState({
        ...state,
        filename: file.name,
      });
      processUpload(page, file);
    } else {
      dispatch(
        publishMessage({
          message: `Your template [${file.name}] download location doesn't match with uploda locaiton.`,
          severity: "error",
        })
      );
    }
  };

  // Execute the upload process
  const processUpload = (page, file) => {
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("action", action);
    formData.append("country", localStorage.getItem("country"));
    formData.append("provinceId", localStorage.getItem("provinceId"));
    formData.append("cityId", localStorage.getItem("cityId"));
    dispatch(showSpinner());
    axios
      .post(
        `${baseUrl("REACT_APP_API_URL")}/api/address/upload/${page}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data;",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) dispatchActionToRefresh(page);
        dispatch(
          publishMessage({
            message: `Your template [${file.name}] successfully processed.`,
            severity: "success",
          })
        );
        let uploadRes = {};
        uploadRes["data"] = res.data.response.data;
        uploadRes["total"] = res.data.response.total;
        uploadRes["failed"] = res.data.response.failed;
        uploadRes["success"] = res.data.response.success;

        setState({
          ...state,
          openSummaryModal: true,
          uploadSummaryResponse: uploadRes,
          page,
        });
        dispatch(hideSpinner());
      })
      .catch((err) => {
        dispatch(hideSpinner());
      });
  };

  const dispatchActionToRefresh = (page) => {
    const country = localStorage.getItem("country");
    const provinceId = localStorage.getItem("provinceId");
    const cityId = localStorage.getItem("cityId");
    switch (page) {
      case "country":
        break;
      case "province":
        dispatch(loadProvince(country, provinceId, cityId));
        break;
      case "city":
        dispatch(loadCity(country, provinceId, cityId));
        break;
      case "area":
        dispatch(loadArea(country, provinceId, cityId));
        break;
      default:
        return;
    }
  };

  //Generate FileName based on Country/Province/City/Area
  const buildFileName = (templateFor) => {
    const country = localStorage.getItem("country");
    const provinceId = localStorage.getItem("provinceId");
    const cityId = localStorage.getItem("cityId");
    switch (templateFor) {
      case "country":
        return country;
      case "province":
        return country;
      case "city":
        return country + "_" + provinceId;
      case "area":
        return country + "_" + provinceId + "_" + cityId;
      default:
        return new Date().toString();
    }
  };

  const downloadTemplateAPI = (templateFor, payload) => {
    const fileName = buildFileName(templateFor);
    axios
      .post(
        `${baseUrl(
          "REACT_APP_API_URL"
        )}/api/address/get/${templateFor}/template`,
        payload
      )
      .then((res) => {
        const file = res.data;
        const blob = new Blob([file], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("hidden", true);
        a.setAttribute("href", url);
        a.setAttribute("download", fileName + ".csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadTemplate = () => {
    let payload = {};
    payload["action"] = action;
    payload["country"] = localStorage.getItem("country");
    payload["provinceId"] = localStorage.getItem("provinceId");
    payload["cityId"] = localStorage.getItem("cityId");
    payload["areaId"] = localStorage.getItem("areaId");

    const path = history.location.pathname;
    switch (path) {
      case "/mapper/country":
        break;
      case "/mapper/province":
        downloadTemplateAPI("province", payload);
        break;
      case "/mapper/city":
        downloadTemplateAPI("city", payload);
        break;
      case "/mapper/area":
        downloadTemplateAPI("area", payload);
        break;
      default:
        break;
    }
  };

  const generateTemplateHTML = () => {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={downloadTemplate}
          endIcon={<GetAppIcon />}
        >
          Template
        </Button>
      </div>
    );
  };

  const uploadHTML = () => (
    <div>
      <input
        accept=".csv"
        className={classes.input}
        id="csv-file-upload"
        type="file"
        onChange={uploadFile}
      />
      <label htmlFor="csv-file-upload">
        <Button
          variant="contained"
          color="default"
          component="span"
          endIcon={<CloudUploadIcon />}
        >
          Upload
        </Button>
      </label>
    </div>
  );

  const closeUploadSummary = () => {
    setState({
      ...state,
      openSummaryModal: false,
      uploadSummaryResponse: null,
    });
  };

  const uploadSummary = () => {
    if (state.openSummaryModal) {
      return (
        <UploadSummary
          open={state.openSummaryModal}
          close={closeUploadSummary}
          tabaledata={state.uploadSummaryResponse}
          tableheader={getTableHeader(state.page)}
        />
      );
    } else {
      return;
    }
  };

  return (
    <Container maxWidth="xl">
      <Paper square>
        <Grid container>
          <Grid item xs={8}>
            <Tabs
              value={action}
              indicatorColor="primary"
              textColor="primary"
              onChange={setActionType}
              aria-label="disabled tabs example"
            >
              <Tab label="Create" value="Create" />
              <Tab label="Update" value="Update" />
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xm={1}>
            {generateTemplateHTML()}
          </Grid>
          <Grid item xm={1}>
            {uploadHTML()}
          </Grid>
        </Grid>
      </div>
      {uploadSummary()}
    </Container>
  );
}

const getTableHeader = (page) => {
  switch (page) {
    case "province":
      return TableHeaders.ProvinceUploadSummaryTableHeader;
    case "city":
      return TableHeaders.CityUploadSummaryTableHeader;
    case "area":
      return TableHeaders.AreaUploadSummaryTableHeader;
    default:
      return;
  }
};
